import { cva, type VariantProps } from "class-variance-authority";
import type { ComponentPropsWithoutRef } from "react";

import { cn } from "~/utils/classnames";

const fieldMessageStyles = cva("mt-1.5 leading-none group-aria-disabled:opacity-60", {
  variants: {
    inputSize: {
      xs: "px-3 text-xs",
      sm: "px-4 text-sm",
      default: "px-4 text-sm",
      lg: "px-5 text-base",
    },
  },
  defaultVariants: {
    inputSize: "default",
  },
});

interface FieldMessageProps
  extends VariantProps<typeof fieldMessageStyles>,
    ComponentPropsWithoutRef<"div"> {
  inputId: string;
}

export function FieldError({ inputId, inputSize, className, ...props }: FieldMessageProps) {
  return (
    <div
      className={cn("text-danger-500", fieldMessageStyles({ inputSize, className }))}
      id={`${inputId}-error`}
      role="alert"
      {...props}
    />
  );
}

export function FieldInfo({ inputId, inputSize, className, ...props }: FieldMessageProps) {
  return (
    <div
      className={cn("text-grey-500", fieldMessageStyles({ inputSize, className }))}
      id={`${inputId}-info`}
      {...props}
    />
  );
}
